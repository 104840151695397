import React from 'react';
import { each, every, keys } from 'lodash';
import DataAid from '../constants/data-aids';
import Field from '../constants/editable-field-tags';
import { UX2, constants as coreConstants } from '@wsb/guac-widget-core';
import { HOURS_PIVOT } from '../constants/routes';
import generateWhatsAppUrl from '../../common/utils/generateWhatsAppUrl';

const sharedMargin = {
  marginBottom: 'medium'
};

const styles = {
  sharedMargin,
  marginReducer: {
    '> :last-child': {
      marginBottom: '0'
    }
  },
  phone: sharedMargin,
  whatsApp: {
    button: {
      'display': 'inline-flex',
      'alignItems': 'center',
      'width': 'auto',
      'backgroundColor': 'brandLivid',
      'border': 'none',
      'borderWidth': '0px',
      'fontSize': 'medium',
      'textTransform': 'none',
      'letterSpacing': 'normal',
      'fontWeight': '700',
      'fontFamily': 'Helvetica Neue, Helvetica, Arial, sans-serif',
      'maxWidth': '88%',
      'marginBottom': 'xlarge',
      ':hover': {
        backgroundColor: 'brandLividHover'
      },
      ':hover:after': {
        display: 'none'
      },
      ':after': {
        display: 'none'
      },
      ':active': {
        backgroundColor: 'brandLivid'
      },
      '@md': {
        'backgroundColor': 'brandLivid',
        'color': 'brandLividContrast',
        ':hover': {
          backgroundColor: 'brandLividHover'
        },
        ':active': {
          backgroundColor: 'brandLivid'
        }
      },
      '@xs-only': {
        paddingHorizontal: 'small'
      },
      '> span': {
        color: 'brandLividContrast'
      }
    }
  }
};

const phoneProps = {
  'data-aid': DataAid.CONTACT_INFO_PHONE_REND
};
const emailProps = {
  'data-aid': DataAid.CONTACT_INFO_EMAIL_REND
};
const urlProps = {
  'data-aid': DataAid.CONTACT_INFO_URL_REND
};

export function renderPhone(widgetProps) {
  if (!widgetProps.phone) {
    return null;
  }
  return (
    <UX2.Element.Text
      key='phone-el'
      data-route={ Field.PHONE }
      children={ widgetProps.phone }
      style={ styles.phone }
      linkify={ true }
      phoneProps={ phoneProps }
      emailProps={ emailProps }
      urlProps={ urlProps }
    />
  );
}

export function renderAddress(widgetProps) {
  const address =
    widgetProps.address && widgetProps.address.value ? (
      <UX2.Element.Text
        key='address-el'
        style={ styles.sharedMargin }
        data-aid={ DataAid.CONTACT_INFO_ADDRESS_REND }
        data-route={ Field.ADDRESS }
        children={ widgetProps.address.value }
      />
    ) : null;
  return address;
}

export function renderBusinessName(widgetProps) {
  const businessName = widgetProps.businessName ? (
    <UX2.Element.Heading.Minor
      key='business-name-el'
      style={ styles.sharedMargin }
      data-aid={ DataAid.CONTACT_INFO_BIZ_NAME_REND }
      data-route={ Field.BUSINESS_NAME }
      children={ widgetProps.businessName }
    />
  ) : null;
  return businessName;
}

export function renderHoursTitle(widgetProps) {
  const hoursTitle =
    widgetProps.hoursTitle ||
    (typeof widgetProps.hoursTitle === 'undefined' && widgetProps.hoursFieldset.hoursTitle);

  return hoursTitle ? (
    <UX2.Element.Heading.Minor
      key='hours-title-el'
      style={ styles.sharedMargin }
      data-route={ Field.HOURS_TITLE }
      data-aid={ DataAid.CONTACT_HOURS_TITLE_REND }
      data-field-route={ HOURS_PIVOT }
      children={ hoursTitle }
    />
  ) : null;
}

export function renderCustomMessage(message) {
  return (
    <UX2.Element.Text
      richtext
      data-route={ Field.HOURS_CUSTOM_MESSAGE }
      data-aid={ DataAid.CONTACT_HOURS_CUST_MSG_REND }
      data-field-route={ HOURS_PIVOT }
      children={ message }
    />
  );
}

export function renderStructuredHours(options, renderHours) {
  const { structuredHours } = options;
  return structuredHours && structuredHours instanceof Array ? (
    <UX2.Element.Block
      key='hours-el'
      data-aid={ DataAid.CONTACT_HOURS_REND }
      data-route={ Field.HOURS }
      data-field-route={ HOURS_PIVOT }
      children={ renderHours(options) }
      style={ styles.sharedMargin }
    />
  ) : null;
}

export function renderHoursInfo(widgetProps, isCollapsed, renderHours) {
  const {
    structuredHours,
    staticContent,
    hoursCustomMessage,
    hours,
    structuredHoursEnabled,
    locale,
    renderMode,
    collapsible = isCollapsed
  } = widgetProps;

  const customMessage = hoursCustomMessage || (!structuredHours && hours);
  if (!(structuredHoursEnabled || customMessage)) {
    return null;
  }
  return (
    <UX2.Element.Block style={ styles.marginReducer }>
      { renderHoursTitle(widgetProps) }
      { structuredHoursEnabled &&
        renderStructuredHours(
          { structuredHours, staticContent, locale, collapsible, renderMode },
          renderHours
        ) }
      { renderCustomMessage(customMessage) }
    </UX2.Element.Block>
  );
}

export function renderCaption(widgetProps) {
  const caption = widgetProps.info ? (
    <UX2.Element.Text
      key='caption-el'
      style={ styles.sharedMargin }
      richtext
      data-aid={ DataAid.CONTACT_INTRO_DESC_REND }
      data-route={ Field.INFO }
      children={ widgetProps.info }
    />
  ) : null;
  return caption;
}

export function renderInfoTitle(widgetProps) {
  const infoTitle = widgetProps.infoTitle ? (
    <UX2.Element.Heading.Minor
      key='info-title-el'
      style={ styles.sharedMargin }
      data-aid={ DataAid.CONTACT_INTRO_HEADING_REND }
      data-route={ Field.INFO_TITLE }
      children={ widgetProps.infoTitle }
    />
  ) : null;
  return infoTitle;
}

export function renderWhatsAppBtn(widgetProps) {
  const { whatsAppEnabled, whatsAppButtonLabel, whatsAppPhone, renderMode, isWhatsAppAvailable } =
    widgetProps;
  const isPublishOrPreview = [
    coreConstants.renderModes.PUBLISH,
    coreConstants.renderModes.PREVIEW
  ].includes(renderMode);
  const whatsAppUrl = generateWhatsAppUrl(whatsAppPhone);
  const getTCCLString = UX2.utils.TCCLUtils.getTCCLString;
  const tcclString = getTCCLString({
    eid: 'ux2.contact.whatsapp.click',
    type: 'click'
  });
  const isWhatsAppEnabled = isWhatsAppAvailable && whatsAppEnabled;
  return (isWhatsAppEnabled && !isPublishOrPreview) ||
    (isWhatsAppEnabled && whatsAppUrl && whatsAppButtonLabel) ? (
      <UX2.Element.Button.External
        key='whats-app-el'
        href={ whatsAppUrl }
        target='_blank'
        style={{ ...styles.sharedMargin, ...styles.whatsApp.button }}
        data-aid={ DataAid.CONTACT_INFO_WHATS_APP_REND }
        data-route={ Field.WHATS_APP }
        data-tccl={ tcclString }
        icon='whatsApp'
        skin='whatsapp'
      >
        { whatsAppButtonLabel }
      </UX2.Element.Button.External>
    ) : null;
}

export default RENDER_INFO_TABLE => {
  const infoKeys = keys(RENDER_INFO_TABLE);
  function isInfoEmpty(widgetProps) {
    const isEmpty = every(infoKeys, key => !RENDER_INFO_TABLE[key](widgetProps));
    return isEmpty;
  }

  function renderInfoElements(widgetProps, collapsible) {
    const infoElements = {};
    each(infoKeys, key => {
      infoElements[key] = RENDER_INFO_TABLE[key](widgetProps, collapsible);
    });
    return infoElements;
  }

  function getOrderedContentElements(props, collapsible) {
    const { phone, address, businessName, hours, caption, infoTitle, whatsApp } =
      renderInfoElements(props, collapsible);
    const top = [infoTitle, caption].filter(el => !!el);
    const middle = [whatsApp, businessName, address, phone].filter(el => !!el);
    const bottom = [hours].filter(el => !!el);
    return [top, middle, bottom]
      .filter(arr => arr.length)
      .map((el, i, arr) => {
        const style = i < arr.length - 1 ? { marginBottom: 'xlarge ' } : {};
        return (
          <UX2.Element.Block key={ `el-${i}` } style={ style }>
            { el }
          </UX2.Element.Block>
        );
      });
  }
  return { isInfoEmpty, renderInfoElements, getOrderedContentElements };
};
