const CLICK_TO_CHAT_URL = 'https://wa.me/';

export default function generateWhatsAppUrl(whatsAppPhone) {
  const phone = whatsAppPhone && whatsAppPhone.phone;
  // phone format:  +<Country Calling Code>.<Line Number>
  // phone example: +123.123456789
  const hasLineNumber = phone && (phone.split('.')[1] || '').length > 0;
  if (hasLineNumber) {
    const unformattedPhone = phone.replace(/\D/g, '');
    return `${CLICK_TO_CHAT_URL}${unformattedPhone}`;
  }
  return null;
}
