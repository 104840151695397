import React from 'react';
import create, {
  renderAddress,
  renderBusinessName,
  renderCaption,
  renderHoursInfo,
  renderInfoTitle,
  renderPhone,
  renderWhatsAppBtn
} from './common';
import HoursComponent from '../hours/Component';

function renderHours(props) {
  return <HoursComponent { ...props } />;
}

const { renderInfoElements, isInfoEmpty, getOrderedContentElements } = create({
  phone: renderPhone,
  address: renderAddress,
  businessName: renderBusinessName,
  hours(widgetProps, isCollapsed) {
    return renderHoursInfo(widgetProps, isCollapsed, renderHours);
  },
  caption: renderCaption,
  infoTitle: renderInfoTitle,
  whatsApp: renderWhatsAppBtn
});

export { renderInfoElements, isInfoEmpty, getOrderedContentElements };
