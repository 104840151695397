import React from 'react';
import PropTypes from 'prop-types';
import InnerForm from '@wsb/guac-widget-shared/lib/components/Form';
import DataAid from '../../common/constants/data-aids';
import { UX2 } from '@wsb/guac-widget-core';
import Field from '../../common/constants/editable-field-tags';
import { FORM_PIVOT } from '../../common/constants/routes';
import ContactInfo from './contact-info';

class Contact extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isFormRevealed: false };
  }

  renderForm() {
    const { formTitle } = this.props;
    const styles = {
      paddingVertical: 'xxlarge',
      width: '100%'
    };

    const titleElement = (
      <UX2.Element.Heading.Minor
        style={{ marginBottom: 'medium' }}
        data-aid={ DataAid.CONTACT_FORM_TITLE_REND }
        data-route={ Field.FORM_TITLE }
        children={ formTitle }
      />
    );

    return (
      <InnerForm
        title={ titleElement }
        onFormClose={ this.toggleFormVisibility }
        dataAidPrefix='CONTACT'
        useCloseButton={ false }
        useCloseIcon
        useColumnLayout
        style={ styles }
        { ...this.props }
      />
    );
  }

  toggleFormVisibility = e => {
    e && e.preventDefault();
    const isFormRevealed = !this.state.isFormRevealed;
    this.setState({ isFormRevealed });
    if (this.props.onFormToggle) {
      this.props.onFormToggle(isFormRevealed);
    }
  };

  generateStyles() {
    const { isFormRevealed } = this.state;

    return {
      grid: {
        textAlign: 'center',
        flexDirection: 'column'
      },
      form: {
        height: 'auto',
        width: '100%',
        left: 0,
        top: 0,
        zIndex: 1,
        transition: 'transform .8s ease, opacity .1s',
        backgroundColor: 'neutral',
        display: isFormRevealed ? 'flex' : 'none',
        justifyContent: 'center',
        position: 'relative',
        boxShadow: 'rgba(0, 0, 0, 0.18) 2px 6px 30px 0px',
        ['@sm']: {
          transform: isFormRevealed ? 'translate3d(0,0,0)' : 'translate3d(-120%,0,0)',
          opacity: isFormRevealed ? 1 : 0,
          position: isFormRevealed ? 'relative' : 'absolute',
          height: 'auto',
          display: 'flex',
          paddingHorizontal: 'xxlarge'
        },
        ['@xs-only']: {
          boxShadow: 'none'
        }
      },
      info: {
        ['@sm']: {
          paddingVertical: 'xxlarge',
          transition: 'opacity 500ms',
          opacity: isFormRevealed ? 0 : 1,
          pointerEvents: isFormRevealed ? 'none' : 'auto',
          position: isFormRevealed ? 'absolute' : 'relative'
        },
        backgroundColor: 'neutral',
        boxShadow: 'rgba(0, 0, 0, 0.18) 2px 6px 30px 0px',
        ['@xs-only']: {
          boxShadow: 'none',
          paddingVertical: 'xlarge'
        }
      }
    };
  }

  render() {
    const { section, category, formEnabled, formTitle } = this.props;
    const styles = this.generateStyles();
    return (
      <UX2.Component.Grid
        inset={ true }
        bottom={ false }
        data-aid={ DataAid.CONTACT_INFO_CONTAINER_REND }
        style={ styles.grid }
      >
        <UX2.Component.Grid.Cell category={ category } inset={ true } style={ styles.info }>
          <ContactInfo { ...this.props } />
          { formEnabled && (
            <UX2.Element.Button.Primary
              tag='button'
              onClick={ this.toggleFormVisibility }
              data-aid={ DataAid.CONTACT_FORM_REVEAL_BUTTON_REND }
              data-route={ Field.FORM_TITLE }
              data-field-route={ FORM_PIVOT }
              children={ formTitle }
              style={{ marginTop: 'xlarge' }}
            />
          ) }
        </UX2.Component.Grid.Cell>
        { formEnabled && (
          <UX2.Component.Grid.Cell
            style={ styles.form }
            data-aid={ DataAid.CONTACT_FORM_CONTAINER_REND }
            category={ category }
            section={ section }
          >
            { this.renderForm() }
          </UX2.Component.Grid.Cell>
        ) }
      </UX2.Component.Grid>
    );
  }
}

Contact.propTypes = {
  formEnabled: PropTypes.bool,
  formTitle: PropTypes.string,
  category: PropTypes.string,
  section: PropTypes.string,
  onFormToggle: PropTypes.func,
  ...InnerForm.propTypes
};

export default Contact;
